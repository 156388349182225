import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import Button from '../../../../components/Button';
import Pagination from '../../../../components/Paginate/Pagination';
import TitleCard from '../../../../components/TitleCard';
import {
  getErrorMessage,
  manageApiErrorResponse
} from '../../../../helpers/handleApiErrorResponse';
import Routes from '../../../_shared/constants/Routes.enum';
import { Paginate } from '../../../_shared/types/api.types';
import { ListEmployeesDto } from '../../dtos/ListEmployeeDto';
import { ProtectedUserClone } from '../../models/User';
import employeeService from '../../service/employee.service';
import EmployeeTable from './components/EmployeeTable';
import FilterEmployees from './components/FilterEmployees';
import FilterEmployeesModal from './components/FilterEmployees/components/FilterEmployeesModal/FilterEmployeesModal';

const ListEmployee = () => {
  const [filterActive, setFilterActive] = useState<ListEmployeesDto>({});
  const [employeesPaginate, setEmployeesPaginate] = useState<Paginate<ProtectedUserClone>>();
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const history = useHistory();

  const loadEmployees = async () => {
    const result = await employeeService.paginateCandidate({ ...filterActive, limit: 10 });
    setEmployeesPaginate(result);
  };

  const onChangePage = async (page: number) => {
    setFilterActive((old) => ({ ...old, page }));
  };

  const handleChangeSearchFilter = (filterValue: ListEmployeesDto) => {
    setFilterActive((old) => ({ ...old, search: filterValue.search }));
  };

  const handleChangeFilter = (filterValue: ListEmployeesDto) => {
    setFilterActive(({ search }) => ({ ...filterValue, search }));
  };

  const handleClickFilter = () => {
    setOpenFilterModal(true);
  };

  const handleClickEditEmplyoee = (userId: number) => {
    history.push(`/employee/${userId}`);
  };

  const handleClickViewEmployeeHolerite = (userId: number) => {
    history.push(`/holerites/${userId}`);
  };

  // const handleClickUnblockChatbot = async (userId: number) => {
  //   try {
  //     const result = await employeeService.unblockChatbot(userId);
  //     toast.success(result.message);
  //     await loadEmployees();
  //   } catch (error) {
  //     handleErrors(error);
  //   }
  // };

  const handleErrors = (resultError: unknown) => {
    const resultErrorResponse = manageApiErrorResponse(resultError);
    const error = getErrorMessage(resultErrorResponse);
    toast.error(error);
  };

  useEffect(() => {
    loadEmployees();
  }, [filterActive]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-wrap justify-between">
        <TitleCard text="Novo funcionário" />
        <div className="flex items-center sm:py-5 py-3 gap-3 w-full sm:w-auto justify-end">
          <Button
            variant="primary"
            type="button"
            onClick={() => history.push(Routes.CREATE_EMPLOYEE)}
          >
            Novo funcionário
          </Button>
        </div>
      </div>

      <div>
        <FilterEmployees onChange={handleChangeSearchFilter} onClick={handleClickFilter} />
      </div>

      <div className="mt-6">
        <EmployeeTable
          employees={employeesPaginate?.results}
          onClickEditEmployee={handleClickEditEmplyoee}
          onClickViewEmployeeHolerite={handleClickViewEmployeeHolerite}
          // onClickUnblockChatbot={handleClickUnblockChatbot}
        />
      </div>

      <div className="mt-4">
        <Pagination
          currentPageLength={employeesPaginate?.results.length}
          page={employeesPaginate?.currentPage}
          pageSize={employeesPaginate?.limit}
          onChangePage={onChangePage}
          totalItems={employeesPaginate?.totalItems}
        />
      </div>
      <FilterEmployeesModal
        isOpen={openFilterModal}
        onChange={handleChangeFilter}
        onClose={() => setOpenFilterModal(false)}
        filterActive={filterActive}
      />
    </div>
  );
};

export default ListEmployee;
